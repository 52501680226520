import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => {

	return {
		root: {
			background   : theme.other.colors.dialog.secondary,
			borderRadius : "6px",
			marginTop    : "6px !important",
			color        : theme.other.colors.text.primary
		},
		label: {
			color: "red"
		},
		dialogContainer: {
			// maxWidth : "500px",
			margin: "auto"
		},
		dialogInputContainer: {
			padding      : "0px !important",
			marginBottom : "1.5rem"
		},
		cssFocused: {
			borderColor: `${ theme.other.paletteNew.primaryDarkBlue }`
		},
		notchedOutline: {
			borderWidth : "1px",
			borderColor : `${ theme.other.colors.border.primary }`
		},
		inputTag: {
			color      : theme.other.colors.text.primary,
			caretColor : theme.other.paletteNew.primaryDarkBlue
		},
		countryCodeContainet: {
			display: "flex"
		},
		countryCodeText: {
			marginLeft  : "5px",
			marginTop   : "1px",
			marginRight : "7px"
		},
		horizontalLine: {
			borderRight: `1px solid ${ theme.other.colors.border.primary }`
		},
		inputDisableSpinner: {
			borderRadius: "1rem",

			"& input[type=number]": {
				mozAppearance: "textfield"
			},
			"& input[type=number]::-webkit-outer-spin-button": {
				webkitAppearance : "none",
				margin           : 0
			},
			"& input[type=number]::-webkit-inner-spin-button": {
				webkitAppearance : "none",
				margin           : 0
			},
			"&::placeholder": {
				fontStyle: "italic"
			},
			"&:hover $notchedOutline": {
				borderColor: `${ theme.other.colors.border.primary }`
			},
			"&$cssFocused $notchedOutline": {
				borderColor: `${ theme.other.paletteNew.primaryDarkBlue }`
			},
			"&$inputError $notchedOutline": {
				borderColor: `${ theme.other.paletteNew.tertiaryErrorRed }`
			}
		},
		loginDialog: {
			margin       : "48px 0",
			position     : "absolute",
			maxWidth     : "unset",
			width        : "min( 60vw, 800px )",
			background   : theme.other.colors.dialog.secondary,
			borderRadius : "1rem"
		},
		enterNoText: {
			marginBottom : "0.5rem",
			marginTop    : "1rem",
			fontSize     : "1.4rem"
		},
		headerText: {
			fontWeight : "500 !important",
			fontSize   : "1.7rem !important"
		},
		leftArrowIcon: {
			height       : "16px",
			width        : "14px",
			paddingRight : "5px",
			marginRight  : "5px"
		},
		alternateContainer: {
			display        : "flex",
			justifyContent : "center",
			alignItems     : "center",
			margin         : "1.5rem 0"
		},
		headerTitle: {
			// padding: "16px 12px 0px !important"
		},
		arrowParent: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "center",
			lineHeight     : 0
		},
		rightArrowContainer: {
			paddingLeft : "34rem",
			marginTop   : "-23px"
		},
		orText: {
			margin: "0 1rem"
		},
		leftDash: {
			width           : "15%",
			height          : "1px",
			backgroundColor : theme.other.colors.border.primary
		},
		rightDash: {
			width           : "15%",
			height          : "1px",
			backgroundColor : theme.other.colors.border.primary
		},
		dashes: {
			color: "#e7e7e7"
		},
		googleSignInButton: {
			marginBottom: "1rem"

		},
		policyContainer: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "center"
		},
		ellipse: {
			backgroundColor : "#6e6e6e",
			borderRadius    : "50%",
			display         : "inline-block",
			height          : "3px",
			width           : "3px",
			margin          : "0 0.6rem"
		},
		policyText: {
			color    : "#126bd5",
			fontSize : "1.1rem",
			cursor   : "pointer"
		},
		rightArrowIcon: {
			height    : "16px",
			width     : "14px",
			transform : "scale(0.7)"
		},
		verifyNowButton: {
			padding  : "6px 10px",
			color    : "rgba(255, 255, 255, 1)",
			fontSize : "1.4rem"
		},
		submitMobileButtonActive: {
			background: theme.other.paletteNew.mainGradient
		},
		backdropOpacity: {
			backgroundColor: "rgba(0, 0, 0, 0.8)"
		},
		otpInput: {
			letterSpacing : "25px",
			textAlign     : "center"
		},
		otpError: {
			color        : theme.other.paletteNew.tertiaryErrorRed,
			marginBottom : "2rem"
		},
		otpMessageContainer: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "space-between"

		},
		dialogContent: {
			flex          : "1",
			display       : "flex",
			flexDirection : "column"
		},
		dialogHeader: {
			padding        : "1rem 1.5rem",
			// backgroundColor : theme.other.colors.dialog.primary,
			display        : "flex",
			justifyContent : "space-between",
			borderBottom   : theme.other.darkMode ? "none" : `1px solid ${ theme.other.colors.border.primary }`
		},
		titleContainet: {
			display    : "flex",
			alignItems : "center"
		},

		closeIcon: {
			height : "3rem",
			width  : "3rem",
			cursor : "pointer"
		},
		mainContent: {
			padding: "0 2rem"
		},
		otpMainContent: {
			padding: "2rem"
		},
		title: {
			marginLeft: "1rem"
		},
		headerTitleIcon: {
			height : "4rem",
			width  : "4rem"
		},
		resetOtpText: {
			color: theme.other.paletteNew.primaryDarkBlue
		},
		marginBottom: {
			marginBottom: "1rem"
		},
		mb2: {
			marginBottom: "2rem"
		},
		inputError: {
			borderColor: `${ theme.other.paletteNew.tertiaryErrorRed } `
		},
		otpInputContainet: {
			marginBottom: "2rem"
		},
		backArrow: {
			height : "2rem",
			width  : "2rem"
		},
		flagIndiaIcon: {
			height : "2.5rem",
			width  : "2.5rem"
		},
		carouselContainer: {
			height: "100%"
		},
		carouselRoot: {
			height: "100%"

		},
		carouselViewport: {
			height: "100%"

		},
		carouselImg: {
			objectFit   : props?.from === "mobile" ? "cover" : "fill",
			height      : "100%",
			aspectRatio : props?.from === "mobile" ? "24/9" : "1/1"
		},
		control: {
			background : "none",
			border     : "none",
			boxShadow  : "none"
		},
		controlIcon: {
			height : "3rem",
			width  : "3rem"
		},
		popupWrapper: {
			display                   : "flex",
			[rooterMediaQuery.mobile] : {
				flexDirection: "column"
			}
		},
		phoneLeftIcon: {
			display        : "flex",
			alignItems     : "center",
			justifyContent : "space-evenly",
			borderRight    : `1px solid ${ theme.other.colors.border.primary }`,
			padding        : "0 0.5rem",
			gap            : "0.25rem"
		},
		inputRoot: {
			fontSize : "1.4rem",
			margin   : "1rem 0"
		},
		input: {
			backgroundColor : theme.other.colors.background.primary,
			color           : theme.other.colors.text.primary,
			fontSize        : theme.other.typographyNew.fontSizeMapping["label"],
			paddingLeft     : "8rem !important",
			height          : "2rem",
			padding         : "2.5rem 0",
			borderWidth     : "1px",
			":focus"        : {
				border: `1px solid ${ theme.other.paletteNew.primaryDarkBlue }`
			}
		},
		icon: {
			width   : "9rem",
			padding : "0.5rem",
			color   : theme.other.colors.text.primary

		},
		pinRoot: {
			justifyContent : "center",
			margin         : "2rem 0",
			border         : `1px solid ${ theme.other.colors.border.primary }`,
			padding        : "1rem",
			borderRadius   : "1rem"
		},
		pinInput: {
			fontSize     : "1.6rem",
			border       : "none !important",
			height       : "3rem",
			width        : "2rem",
			borderRadius : "1.1rem",
			background   : "none"

		},
		pinError: {
			color        : theme.other.paletteNew.tertiaryErrorRed,
			marginBottom : "2rem"

		},
		tvinput: {
			textAlign    : "center",
			borderRadius : "1rem",
			border       : "none",
			fontSize     : "1.6rem",
			padding      : "2.2rem 0rem",
			background   : "none",

			"& input[type=number]": {
				mozAppearance: "textfield"
			},
			"& input[type=number]::-webkit-outer-spin-button": {
				webkitAppearance : "none",
				margin           : 0
			},
			"& input[type=number]::-webkit-inner-spin-button": {
				webkitAppearance : "none",
				margin           : 0
			},
			"&::placeholder": {
				fontStyle: "italic"
			},
			"&:hover $notchedOutline": {
				borderColor: `${ theme.other.colors.border.primary }`
			},
			"&$cssFocused $notchedOutline": {
				borderColor: `${ theme.other.paletteNew.primaryDarkBlue }`
			},
			"&$inputError $notchedOutline": {
				borderColor: `${ theme.other.paletteNew.tertiaryErrorRed }`
			}
		},
		continueBtn: {
			marginTop: "1rem"
		}
	};
} );
